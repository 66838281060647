import { LocalSessionKey } from '../burger-king-lib/constants/app-constants';
import {
  LocalStorage,
  // LocalStorage,
  SessionStorage
} from '../burger-king-lib/utils/storage.web';
import { ROUTES as _routes } from '../constants/app-constants';

export function getCartItemPayload(order_type, is_for_count) {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    user_id: LocalStorage.get(LocalSessionKey.USER_ID) || '0',
    order_type,
    is_for_count,
    MaxDiscountValue: SessionStorage.get('maximum_discount_value') || ''
  };
}

export function addToCartPayload(
  theme,
  isAdd,
  item,
  is_repeat,
  addons,
  customise,
  custom_quantity,
  customiseClicked,
  variant_name,
  allData
) {
  const {
    entity_id,
    name,
    product_entity_id,
    id,
    variant_category,
    taxes,
    is_addon,
    is_customise,
    offer_coupon_code,
    product_id,
    is_from_offer,
    is_combo,
    image_urls,
    coupon_code,
    is_offer,
    newId,
    category_id
  } = item;
  function getProductIdOrId(isAdd, product_id, id) {
    if (isAdd) {
      if (product_id && product_id.includes('-')) {
        return product_id;
      } else if (id && id.includes('-')) {
        return id;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const getQuantity = () => {
    let quantity = 1;

    if (allData?.isCustomise === 'customise') {
      quantity = custom_quantity ?? 1;
    } else if (allData?.isCustomise === 'addnew') {
      quantity = 1;
    } else {
      quantity = allData?.payloadForNextApi?.quantity ?? 1;
    }
    return quantity;
  };
  const image_url =
    (image_urls && image_urls[0].images[0].image['1x']) || item.image_url;
  const payload = {
    ...item,
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    id,
    name,
    cart_added_for: theme === 'takeaway' ? 'dinein' : theme,
    entity_id:
      !entity_id || entity_id.includes('V')
        ? product_entity_id
        : !entity_id
        ? newId
        : entity_id,

    price: item?.price,
    tax: taxes,
    is_adding: isAdd,
    image_url,
    addons: addons || [],
    is_repeat: is_repeat || false,
    is_customise: customiseClicked,
    is_combo: is_combo || false,
    customise_id: (customise && customise.customise_id) || '',
    customise_name: (customise && customise.customise_name) || '',
    is_addon: !!addons?.length || false,
    quantity: getQuantity(),
    is_from_offer: is_from_offer || false,
    is_offer: is_offer || false,
    category_id,
    variant_name: variant_name,
    product_id: getProductIdOrId(isAdd, product_id, id),
    offer_coupon_code: item?.offer_coupon_code
      ? item?.offer_coupon_code
      : variant_category?.[0]?.variants?.[0]?.offer_coupon_code
      ? variant_category?.[0]?.variants?.[0]?.offer_coupon_code
      : ''
  };
  // if (customiseClicked) {
  //   payload.product_id = product_id;
  // }
  if (newId != null && newId != undefined) {
    payload.entity_id = newId;
  }
  if (
    typeof variant_name === 'undefined' &&
    allData?.location?.state?.extra &&
    Object.keys(allData.location.state.extra).length > 0
  ) {
    payload.variant_name =
      allData?.content?.variant_name ??
      allData?.payloadForNextApi?.variant_name ??
      '';
  }
  if (payload.coupon_code === '') {
    delete payload.coupon_code;
  }
  return payload;
}

export function addToCartUpdatedPayload(item, addons, newItems) {
  console.log(newItems, ' newItems ');
  item = [item];
  let payload = {};
  if (addons && addons.length) {
    payload = {
      data: item.concat(addons)
    };
  } else {
    payload = {
      data: item
    };
  }
  if (newItems && newItems.length) {
    newItems.forEach(prod => item.push(prod));
  }
  return payload;
}

export function removeCartItemPayload(
  product_id,
  cart_id,
  is_adding,
  is_repeat
) {
  return {
    product_id,
    cart_id,
    is_adding,
    is_repeat
  };
}

export function alterCartItemPayload(
  product_id,
  // cart_id,
  is_adding
  // is_repeat
) {
  return {
    product_id,
    // cart_id,
    is_adding,
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString()
    // is_repeat
  };
}

export function getProdutFromCartPayload(product_id, theme) {
  return {
    product_id,
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    order_type: theme
  };
}

export function getAddonsListPayload(cart_added_for) {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    user_id: LocalStorage.get(LocalSessionKey.USER_ID) || '0',
    cart_added_for
  };
}

export function getAddonsPayload(variant_id, product_id, name = '') {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    variant_id,
    product_id,
    name: name
  };
}

export function getAddonsPayloadOld(entity_id) {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    entity_id,
    name: ''
  };
}

export function getOutletChangePayload(long, lat) {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    lat,
    long
  };
}

export function getCheckoutCartPayload(
  // cart_id,
  location_id,
  type,
  order_type,
  is_charity_added,
  charity_item,
  ip,
  randomString
) {
  const hasCharityData = !!(
    charity_item &&
    charity_item.product &&
    Object.keys(charity_item.product).length > 0 &&
    is_charity_added
  );
  const updatedIsCharityAdded = hasCharityData || false;
  return {
    outlet_id:
      window &&
      window.sessionStorage &&
      window.sessionStorage.getItem('OUTLET_ID'),
    location_id,
    type,
    order_type,
    is_charity_added: updatedIsCharityAdded,
    charity_item: updatedIsCharityAdded ? charity_item : {},
    redirect_url: window.location.origin + _routes.ORDER_STATUS,
    simpl_device_params: {
      user_agent: window?.navigator?.userAgent,
      simpl_ipa: ip
    },
    cart_locking: randomString
  };
}

const getIpaddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org/?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
  }
};

export function getApplyCouponPayload(
  coupon_code,
  total_payable_amount,
  cart_id
) {
  return {
    coupon_code,
    total_payable_amount,
    cart_id,
    phone_no: LocalStorage.get(LocalSessionKey.USER_DATA)
  };
}

export function getApplyCouponPayloadRedis(coupon_code) {
  return {
    coupon_code,
    outlet_id: window && SessionStorage.get(LocalSessionKey.OUTLET_ID),
    phone_no: window && LocalStorage.get(LocalSessionKey.USER_DATA)
  };
}

export function getCouponPayload(total_payable_amount, cart_id) {
  return {
    total_payable_amount,
    cart_id
  };
}

export function getRemoveCouponPayload(cart_id) {
  return {
    cart_id
  };
}

export function getCharityProductPayload() {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString()
  };
}

export function getFeedbackPayload(payload) {
  const {
    order_id,
    type,
    food_experience,
    delivery_experience,
    delivery_packing,
    restaurant_experience,
    staff_service,
    remarks,
    app_performance,
    overall_rating
  } = payload;
  return {
    order_id,
    type,
    overall_rating,
    app_performance,
    food_experience,
    delivery_experience,
    delivery_packing,
    staff_service,
    restaurant_experience,
    remarks
  };
}

export function verifyOfferOtpPayload({
  cart_id,
  coupon_code,
  request_id,
  otp
}) {
  return {
    phone_no: LocalStorage.get(LocalSessionKey.USER_DATA),
    cart_id,
    coupon_code,
    RequestID: request_id,
    otp
  };
}

export function resendOfferOtpPayload() {
  return { phone_no: LocalStorage.get(LocalSessionKey.USER_DATA) };
}
