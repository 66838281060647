import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app';
import * as serviceWorker from './serviceWorker';
import cleverTap from 'clevertap-web-sdk';

cleverTap.init(process.env.REACT_APP_CLEVER_TAP_ID, '', '', 'TEST-6a6-cc2', {
  debug: true
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
